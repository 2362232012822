import './src/styles/global.css';

export const onInitialClientRender = () => {
  const hash = window.location.hash;
  if (!hash) return;

  const target = document.querySelector(hash);
  if (!target) return;

  const headerHeight =
    document.getElementById('main-header')?.offsetHeight || 0;
  const targetPositionY =
    target.getBoundingClientRect().top + window.scrollY - headerHeight;

  window.scrollTo({
    top: targetPositionY,
    behavior: 'smooth',
  });
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // overwrite wrong default browser scroll positioning
  requestAnimationFrame(() => {
    window.scrollTo(...(getSavedScrollPosition(location) || [0, 0]));
  });

  return false;
};
